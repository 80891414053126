<template>
    <div class="topicList">
        <div class="topicItem" v-for="(item,index) in topicList" :key="index" @click="tagJump(item)">
            <div class="topicInfo">
                <div class="portrait">
                    <ImgDecypt class="avatarImg" :src="item.coverImg" />
                </div>
                <div class="topicLabel">
                    <div class="topicTitle">
                        <span>{{item.name}}</span>
                    </div>
                    <div class="topicDtails flex-aic">
                     <div>
                      <svg-icon class="icons" iconClass="collCount" />
                      <span>{{ item.collCount | watchCount }}</span>
                     </div>
                     <div>
                      <svg-icon class="icons" iconClass="playCount" />
                      <span>{{ item.playCount | watchCount}}</span>
                     </div>
                    </div>
                    <div class="topicDtails">
                        <span>{{item.tagDesc}}</span>
                    </div>
                </div>
            </div>
            <div class="btn" @click.stop="focus(item)">
                <div class="focusBtn" v-if="!item.hasCollected">
                 <img src="@/assets/png/communityFollow.png">
<!--                    <svg-icon icon-class="btnAdd" class="btnIcon"></svg-icon>-->
<!--                 <span>-->
<!--                    关注-->
<!--                </span>-->
                </div>
                <div class="alreadyFocus" v-else>
                 <img src="@/assets/png/noCommunityFollow.png">
<!--                <span>-->
<!--                    已关注-->
<!--                </span>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImgDecypt from "@/components/ImgDecypt";
import {collectTag} from "@/api/community";

export default {
  name: "TopicList",
    components:{
        ImgDecypt
    },
    props:{
        topicList:{
            type: Array
        }
    },methods:{
        async focus(item) {
            /**
             * 关注/取消关注 话题
             */
            let req = {
                isCollect: !item.hasCollected,
                objID: item.id,
                type: "tag"
            }
            let res = await this.$Api(collectTag, req);

            if(res && res.code == 200){
                if(!item.hasCollected){
                    item.hasCollected = true;
                    this.$toast({
                        message: '关注成功',
                        position: 'top',
                    });
                }else{
                    item.hasCollected = false;
                    this.$toast({
                        message: '取消关注',
                        position: 'top',
                    });
                }
            }
        },tagJump(item){
            /**
             * 话题详情
             */
            this.$router.push({
                path:"/topicDetails",
                query:{
                    id:item.id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .topicList{
     padding: 0 10px;
        .topicItem{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            margin-top: 10px;
            height: 79px;
            background: #fff;
            border-radius: 10px;
            margin-bottom: 20px;
            box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);

         .topicInfo{
                display: flex;
                align-items: center;

                .portrait{
                    width: 55px;
                    height: 55px;
                    //border-radius: 50%;
                    //background: rgb(230,230,230);

                    .avatarImg{
                        border-radius: 4px;

                        /deep/ .van-image__img{
                            border-radius: 4px;
                        }
                    }
                }

                .topicLabel{
                    padding-left: 8px;

                    .topicTitle{
                        font-size: 16px;
                        color: #333333;
                        font-weight: 600;
                    }

                    .topicDtails{
                        font-size: 12px;
                        color: #999999;

                        .icons {
                         width: 14px;
                         height: 14px;
                         margin-right: 2px;
                         vertical-align: middle;
                        }
                        span {
                         vertical-align: middle;
                        }

                        div:nth-child(2) {
                         margin-left: 8px;
                        }
                    }
                }
            }


            .btn{
                //display: flex;
                //justify-content: center;
                //align-items: center;
                //margin-bottom: 6px;

                .btnIcon{
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                }

                .focusBtn{
                 width: 63px;
                 height: 26px;
                 //font-size: 12px;
                 //padding: 3px 10px;
                 //background: rgb(113, 69, 231);
                 //border-radius: 12.5px;
                 //color: #94D6DA;
                 //border: solid #94D6DA 1px;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                    img {
                     width: 63px;
                     height: 26px;
                    }
                }

                .alreadyFocus{
                 width: 63px;
                 height: 26px;
                 //font-size: 12px;
                 //padding: 3px 12px;
                 //background: #B3B3B3;
                 //border-radius: 12.5px;
                 //color: #FFFFFF;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 //border: solid #94D6DA 2px;
                 img {
                  width: 63px;
                  height: 26px;
                 }
                }
            }
        }
    }
</style>
